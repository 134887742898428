<template>
	<div class="page-index">
		<div class="app-logo">
			<img class="logo" src="../../assets/images/logo.png">
		</div>
		<select-country v-if="!isLogin" :labelIndex="zIndex" :isShow="!isLogin" :text="Nation"
			@change="setNation" />
	
	</div>
</template>

<script>
import selectCountry from '../../components/select-country/lpselect-countrty.vue';
	export default {
		components: {
			selectCountry,
			
		},
		data() {
			return {
				Nationlist: {
					TH: "https://linktr.ee/simplusappliance",
					ID: "https://linktr.ee/simplus_ID",
					VN: "https://linktr.ee/simplus_vn",
					MY: "https://linktr.ee/simplus_my",
					SG: "https://linktr.ee/simplus_sg",
					PH: "https://linktr.ee/simplus_ph",
				},
				Nation: '',
				isLogin: false,
				zIndex: '1999'
			};
		},
	mounted() {
	
		},
		methods: {
			handReload() {
				
			},
			setNation(e) { 
				// 跳转 浏览器 不可后退
				window.location.href = this.Nationlist[e]	
				this.Nation = e
				this.zIndex = '2016'
			}
		}
	};
</script>

<style lang="less">
	.page-index {
		width: 100%;
		height: 100vh;
		background: url('../../assets/images/bg-image.png') center center;
		background-size: cover;
		-webkit-background-size: contain;
		-o-background-size: contain;
		background-color: #e4e8f1;
		margin: 0 auto;

		.app-logo {
			padding: 88px;
			display: flex;
			justify-content: center;

			.logo {
				width: 220px;
				height: 60px;
			}
		}
	}
</style>
